import { Dispatch, FC, SetStateAction } from 'react';

import { CartItem } from '@/apollo/reactiveVars';
import { ProductVariantFragment } from '@/catalogueFragments';

import SelectVariant from './SelectVariant';

import styles from './ShopListItemVariantSelect.module.scss';

export type Props = {
    productId?: string;
    variants?: ProductVariantFragment[] | null;
    selectedSize: number;
    setSelectedSize: Dispatch<SetStateAction<number>>;
    cartItems: CartItem[];
};

const ShopListItemVariantSelect: FC<Props> = ({ variants, ...restProps }) => {
    const singleVariant = (variants || []).length <= 1;

    return (
        <div className={styles['wrapper']}>
            {(variants || []).map((variant, index) => {
                return (
                    <SelectVariant
                        key={index}
                        variant={variant}
                        index={index}
                        singleVariant={singleVariant}
                        {...restProps}
                    />
                );
            })}
        </div>
    );
};

export default ShopListItemVariantSelect;
