import { FC, ReactNode, memo } from 'react';
import DefaultErrorPage from 'next/error';
import { useRouter } from 'next/router';
import { NetworkStatus, ApolloQueryResult } from '@apollo/client';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    GetPaginatedProductsQuery,
    useGetPaginatedProductsQuery,
} from '@/catalogueQueries/GetPaginatedProductsQuery';
import { asTypename } from '@/crystallize/helpers';
import { PAGE_SIZE } from '@/pages/index';

import { Button } from '@/ui';
import { ShopListItem } from '../ShopListItem';
import { InfiniteScroll } from '@/components/InfiniteScroll';
import { ErrorBoundary } from '@/components/ErrorBoundary';

import styles from './ShopItemList.module.scss';

const messages = defineMessages({
    serverError: {
        id: 'components.shopItemList.shopItemList.serverError',
        defaultMessage: 'Serverio klaida',
    },
});

const ShopItemList: FC = () => {
    const { locale } = useRouter();
    const { formatMessage } = useIntl();
    const { data, error, fetchMore, networkStatus } = useGetPaginatedProductsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        variables: {
            language: locale as Locale,
            first: PAGE_SIZE,
            after: null,
        },
    });

    const { edges, pageInfo } = data?.catalogue?.subtree || {};
    const { hasNextPage, totalNodes } = pageInfo || {};
    const edgesLength = (edges || []).length;
    const cursor = edgesLength ? (edges || [])[edgesLength - 1].cursor : '';
    const loadingMoreProducts =
        networkStatus === NetworkStatus.fetchMore && (totalNodes || 0) > edgesLength;

    if (error) {
        return (
            <DefaultErrorPage
                withDarkMode={false}
                statusCode={503}
                title={formatMessage(messages.serverError)}
            />
        );
    }

    if (data) {
        return (
            <div className={styles['wrapper']}>
                <ul className={styles['list']}>
                    {(edges || []).map((edge, idx) => {
                        const product = asTypename(edge?.node, 'Product');
                        return (
                            <ShopListItem
                                key={`${product?.id}-${idx}`}
                                index={idx}
                                product={product}
                            />
                        );
                    })}
                </ul>
                <ErrorBoundary
                    fallback={(): ReactNode => (
                        <div className={styles['loadMore']}>
                            <Button
                                loading={loadingMoreProducts}
                                disabled={loadingMoreProducts}
                                type="primary"
                                onClick={(): Promise<
                                    ApolloQueryResult<GetPaginatedProductsQuery>
                                > =>
                                    fetchMore({
                                        variables: {
                                            language: locale as Locale,
                                            after: cursor,
                                            first: PAGE_SIZE,
                                        },
                                    })
                                }
                            >
                                <FormattedMessage
                                    id="components.shopItemList.shopItemList.loadMore"
                                    defaultMessage="Daugiau"
                                />
                            </Button>
                        </div>
                    )}
                >
                    <InfiniteScroll
                        isLoading={loadingMoreProducts}
                        hasNextPage={!!hasNextPage}
                        cursor={cursor}
                        pageSize={PAGE_SIZE}
                        callback={fetchMore}
                    />
                </ErrorBoundary>
            </div>
        );
    }

    return null;
};

export default memo(ShopItemList);
