import { Component, ReactNode, ErrorInfo } from 'react';

export type ErrorBoundaryFallbackProps = {
    error: Error;
};

type ErrorBoundaryProps = {
    fallback?: (props: ErrorBoundaryFallbackProps) => ReactNode;
    /**
     * used for testing
     */
    _logFn?: (error: Error, componentStack: string) => void;
    children?: ReactNode;
};

type ErrorBoundaryState = {
    error: Error | null;
};

const defaultLogFn = (error: Error, componentStack: string): void =>
    // eslint-disable-next-line no-console
    console.error(error, componentStack);

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { error };
    }

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        const logFn = this.props._logFn || defaultLogFn;
        logFn(error, errorInfo.componentStack);
    }

    render(): ReactNode {
        const { fallback } = this.props;
        const { error } = this.state;

        if (error !== null) {
            if (fallback) {
                return fallback({ error });
            } else {
                // render a basic default error message
                return (
                    <div>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            <summary>An error occurred.</summary>
                            {error.message}
                        </details>
                    </div>
                );
            }
        }
        return this.props.children;
    }
}
