import { FC } from 'react';
import Cookies from 'js-cookie';
import classnames from 'classnames';
import { defineMessage, useIntl } from 'react-intl';

import { CartItem } from '@/apollo/reactiveVars';
import { ReactiveVar } from '@apollo/client';
import { Button } from '@/ui';
import { ShoppingCartAdd } from '@/components/Icons';

import { replaceItemAtIndex } from '@/helpers';
import { getItemIndexInCart } from '@/components/ShoppingCart/helpers';

import styles from './AddShopListItemToCart.module.scss';

type Props = {
    productId?: string | null;
    name?: string | null;
    sku?: string | null;
    size?: string | null;
    price?: number | null;
    path?: string | null;
    productType?: string | null;
    showAnimation: boolean;
    handleShowAnimation: () => void;
    cartItems: CartItem[];
    setCartItems: ReactiveVar<CartItem[]>;
    quantityInStock: number;
    quantityInCart: number;
};

const message = defineMessage({
    id: 'components.shopItemList.addShopListItemToCart',
    defaultMessage: 'Pridėti į krepšelį',
});

const AddShopListItemToCart: FC<Props> = ({
    productId,
    name,
    sku,
    size,
    price,
    path,
    productType,
    showAnimation,
    handleShowAnimation,
    cartItems,
    setCartItems,
    quantityInStock,
    quantityInCart,
}) => {
    const { formatMessage } = useIntl();
    const hasProductProps = !!productId && !!sku && !!name && !!size && !!price && !!path;

    const addToCart = (): void => {
        if (quantityInStock > quantityInCart && hasProductProps) {
            handleShowAnimation();
            const itemIndexInCart = getItemIndexInCart(cartItems, sku);
            if (itemIndexInCart !== -1) {
                const updatedCart = replaceItemAtIndex(cartItems, itemIndexInCart, {
                    productId,
                    sku,
                    path,
                    quantity: quantityInCart + 1,
                });

                setCartItems(updatedCart);

                const cookieData = JSON.stringify(updatedCart);
                Cookies.set('CART_DATA', cookieData, {
                    expires: 30,
                    sameSite: 'none',
                    secure: true,
                });
            } else {
                const updatedCart = [
                    ...cartItems,
                    {
                        productId,
                        sku,
                        path,
                        quantity: 1,
                        price,
                    },
                ];

                setCartItems(updatedCart);

                const cookieData = JSON.stringify(updatedCart);
                Cookies.set('CART_DATA', cookieData, {
                    expires: 30,
                    sameSite: 'none',
                    secure: true,
                });
            }

            if (
                process.env.NODE_ENV === 'production' &&
                Cookies.get('COOKIE_CONSENT_FULL') &&
                typeof window.gtag !== 'undefined'
            ) {
                gtag('event', 'add_to_cart', {
                    currency: 'EUR',
                    items: [
                        {
                            id: sku,
                            name,
                            category: productType,
                            price,
                            quantity: 1,
                            variant: size,
                        },
                    ],
                    value: price,
                });
            }
        } else {
            return;
        }
    };

    return (
        <>
            <div
                className={classnames(styles['wrapper'], {
                    [styles['animating']]: showAnimation,
                })}
            >
                <Button
                    className={styles['button']}
                    onClick={(): void => addToCart()}
                    disabled={showAnimation}
                    ariaLabel={formatMessage(message)}
                >
                    <ShoppingCartAdd className={styles['icon']} width={32} height={32} />
                    <span className={styles['animationWrapper']}>
                        <span className={styles['pulse']}></span>
                        <span className={styles['pulse2']}></span>
                    </span>
                </Button>
            </div>
        </>
    );
};

export default AddShopListItemToCart;
