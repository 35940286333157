import { FC } from 'react';
import classnames from 'classnames';

import { Radio } from '@/ui';

import { getItemQuantityInCart } from '@/components/ShoppingCart/helpers';
import { getSizeAttribute } from '@/crystallize/helpers';

import { ProductVariantFragment } from '@/catalogueFragments';
import { Props as ShopListItemVariantSelectProps } from './ShopListItemVariantSelect';

import styles from './ShopListItemVariantSelect.module.scss';

type Props = Omit<ShopListItemVariantSelectProps, 'variants'> & {
    variant: ProductVariantFragment;
    index: number;
    singleVariant: boolean;
};

const SelectVariant: FC<Props> = ({
    productId,
    cartItems,
    variant,
    singleVariant,
    selectedSize,
    setSelectedSize,
    index,
}) => {
    const { attributes, sku, stock } = variant || {};
    const size = getSizeAttribute(attributes) || '';
    const quantityInStock = stock || 0;
    const quantityInCart = getItemQuantityInCart(cartItems, sku);

    const handleChange = (): void => {
        if (selectedSize === index) {
            return;
        }

        setSelectedSize(index);
    };

    if (singleVariant) {
        return (
            <div
                className={classnames(styles['variant'], styles['is-active'], {
                    [styles['strikethrough']]: quantityInStock <= quantityInCart,
                })}
            >
                {size}
            </div>
        );
    }

    return (
        <div className={styles['variant']}>
            <Radio
                value={size}
                checked={!selectedSize ? index === 0 : selectedSize === index}
                labelClass={quantityInStock <= quantityInCart ? styles['strikethrough'] : ''}
                name={productId || ''}
                onChange={handleChange}
                disabled={quantityInStock <= quantityInCart}
                label={size}
            />
        </div>
    );
};

export default SelectVariant;
