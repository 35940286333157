import { FC, useState, useEffect } from 'react';
import NextLink from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useReactiveVar } from '@apollo/client';
import classnames from 'classnames';

import { cartItemsVar, showCartIconAnimationVar } from '@/apollo/reactiveVars';
import {
    CHINCHILLA_FOOD_PATH,
    RABBIT_FOOD_PATH,
    GUINEA_PIG_FOOD_PATH,
} from '@/crystallize/constants';

import { Link } from '@/ui';
import { AddShopListItemToCart } from '../AddShopListItemToCart';
import { ShopListItemVariantSelect } from '../ShopListItemVariantSelect';

import { getItemQuantityInCart } from '@/components/ShoppingCart/helpers';
import {
    getDiscountPrice,
    getProductTypeTopic,
    getSizeAttribute,
    getProductName,
} from '@/crystallize/helpers';

import { ProductFragment } from '@/catalogueFragments';

import styles from './ShopListItem.module.scss';

type Props = {
    product: ProductFragment | null;
    index: number;
};

const ShopListItem: FC<Props> = ({ product, index }) => {
    const isEssentialsFood =
        product?.path === CHINCHILLA_FOOD_PATH ||
        product?.path === RABBIT_FOOD_PATH ||
        product?.path === GUINEA_PIG_FOOD_PATH;
    const [selectedSize, setSelectedSize] = useState(isEssentialsFood ? 1 : 0);
    const [showButton, setShowButton] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    const router = useRouter();
    const asPathWithoutQuery = router.asPath.split('?')[0];
    const asPathNestedRoutes = asPathWithoutQuery.split('/').filter(p => p.length > 0);
    const root = asPathNestedRoutes[0];

    const cartItems = useReactiveVar(cartItemsVar);

    const { id, title, variants, path, topics } = product || {};
    const innerPath = root ? path?.replace('/shop/', `/${root}/`) : path;
    const name = getProductName(title);
    const { sku, attributes, price, stock, priceVariants, images } =
        (variants || [])[selectedSize] || {};

    // atributes
    const size = getSizeAttribute(attributes);

    // topics
    const productType = getProductTypeTopic(topics);

    // priceVariants
    const discountPrice = getDiscountPrice(priceVariants);

    // images
    const { url: src, altText: alt } = (images || [])[0] || {};
    const priority = index < 3;

    const quantityInCart = getItemQuantityInCart(cartItems, sku);
    const quantityInStock = stock || 0;

    useEffect(() => {
        if (quantityInCart < quantityInStock) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    }, [cartItems, selectedSize, quantityInCart, quantityInStock]);

    const handleShowAnimation = (): void => {
        setShowAnimation(true);
        showCartIconAnimationVar(true);
        setTimeout(() => {
            setShowAnimation(false);
            showCartIconAnimationVar(false);
        }, 1000);
    };

    return (
        <li
            className={classnames(styles['shopListItem'], {
                [styles['is-animating']]: showAnimation,
            })}
        >
            <NextLink href={innerPath || ''} passHref>
                <a>
                    <span className={styles['background']}>
                        <span className={styles['imageWrapper']}>
                            <Image
                                src={src || ''}
                                alt={alt || ''}
                                layout="fill"
                                objectFit="contain"
                                priority={priority}
                            />
                        </span>
                    </span>
                </a>
            </NextLink>
            <h2 className={styles['name']}>
                <Link href={innerPath || ''} black>
                    {name}
                </Link>
            </h2>
            <div className={styles['selectWrapper']}>
                <ShopListItemVariantSelect
                    productId={id}
                    variants={variants}
                    selectedSize={selectedSize}
                    setSelectedSize={setSelectedSize}
                    cartItems={cartItems}
                />
                {typeof price === 'number' && (
                    <>
                        {discountPrice && (
                            <div
                                className={classnames(styles['price'], {
                                    [styles['with-discount']]: discountPrice,
                                })}
                            >
                                {discountPrice.toFixed(2)} €
                            </div>
                        )}
                        <div
                            className={classnames(styles['price'], {
                                [styles['has-discount']]: discountPrice,
                            })}
                        >
                            {price.toFixed(2)} €
                        </div>
                    </>
                )}
            </div>
            {showButton && (
                <AddShopListItemToCart
                    productId={id}
                    name={name}
                    sku={sku}
                    size={size}
                    price={discountPrice || price}
                    path={path}
                    productType={productType}
                    showAnimation={showAnimation}
                    handleShowAnimation={handleShowAnimation}
                    cartItems={cartItems}
                    setCartItems={cartItemsVar}
                    quantityInStock={quantityInStock}
                    quantityInCart={quantityInCart}
                />
            )}
        </li>
    );
};

export default ShopListItem;
