import { ReactNode } from 'react';
import NextLink from 'next/link';
import Image, { StaticImageData } from 'next/image';

import { OxbowLogo } from '@/components/Icons';

import { OXBOW_LINK, HAPPY_ONE_LINK, FRESH_NEWS_LINK } from '@/data/constants';

import hay from '../../public/assets/content/products/oxbow-hay-collection.png';
import food from '../../public/assets/content/products/food-collection.png';
import treats from '../../public/assets/content/products/oxbow-treat-collection.png';
import supplements from '../../public/assets/content/products/oxbow-supplement-collection.png';
import accesssories from '../../public/assets/content/products/accessories-collection.png';
import litter from '../../public/assets/content/products/oxbow-litter-collection.png';

export type Topic = 'food' | 'hay' | 'treats' | 'supplements' | 'accessories' | 'litter';

export type ProductTypesMap = {
    [key in Locale]: {
        id: Topic;
        title: string;
        image: { staticPath: StaticImageData; alt: string };
    }[];
};

export const productTypesMap: ProductTypesMap = {
    lt: [
        {
            id: 'food',
            title: 'Maistas',
            image: {
                staticPath: food,
                alt: 'Mistas graužikams',
            },
        },
        {
            id: 'hay',
            title: 'Šienas',
            image: {
                staticPath: hay,
                alt: 'Šienas graužikams',
            },
        },
        {
            id: 'treats',
            title: 'Skanėstai',
            image: {
                staticPath: treats,
                alt: 'Skanėstai graužikams',
            },
        },
        {
            id: 'supplements',
            title: 'Papildai',
            image: {
                staticPath: supplements,
                alt: 'Papildai graužikams',
            },
        },
        {
            id: 'accessories',
            title: 'Priedai',
            image: {
                staticPath: accesssories,
                alt: 'Priedai graužikams',
            },
        },
        {
            id: 'litter',
            title: 'Kraikas',
            image: {
                staticPath: litter,
                alt: 'Kraikas graužikams',
            },
        },
    ],
    en: [
        {
            id: 'food',
            title: 'Food',
            image: {
                staticPath: food,
                alt: 'Food for small animals',
            },
        },
        {
            id: 'hay',
            title: 'Hay',
            image: {
                staticPath: hay,
                alt: 'Hay for small animals',
            },
        },
        {
            id: 'treats',
            title: 'Treats',
            image: {
                staticPath: treats,
                alt: 'Treats for small animals',
            },
        },
        {
            id: 'supplements',
            title: 'Supplements',
            image: {
                staticPath: supplements,
                alt: 'Supplements for small animals',
            },
        },
        {
            id: 'accessories',
            title: 'Accessories',
            image: {
                staticPath: accesssories,
                alt: 'Accessories for small animals',
            },
        },
        {
            id: 'litter',
            title: 'Litter',
            image: {
                staticPath: litter,
                alt: 'Litter for small animals',
            },
        },
    ],
};

export type LogoLinkKey = keyof typeof logoLinkMap;

export const logoLinkMap = {
    oxbow: (
        <NextLink href={OXBOW_LINK} passHref>
            <a target="_blank" rel="noopener noreferrer">
                <OxbowLogo width={70} height={62} />
            </a>
        </NextLink>
    ),
    happyOne: (
        <NextLink href={HAPPY_ONE_LINK} passHref>
            <a target="_blank" rel="noopener noreferrer">
                <Image
                    src="/assets/content/happy-one-mediterraneum.png"
                    alt="Happy One Mediterraneum Logo"
                    width={70}
                    height={70}
                />
            </a>
        </NextLink>
    ),
    freshNews: (
        <NextLink href={FRESH_NEWS_LINK} passHref>
            <a target="_blank" rel="noopener noreferrer">
                <Image
                    src="/assets/content/fresh-news.png"
                    alt="Fresh News Logo"
                    width={182}
                    height={70}
                />
            </a>
        </NextLink>
    ),
};

export type TopicPathProps = {
    title: ReactNode;
    description: ReactNode[];
    metaTitle: string;
    metaDescription: string;
    logos: (keyof typeof logoLinkMap)[];
};

export type TopicPathMap<T extends string> = {
    [key in T]: {
        [key in Locale]: TopicPathProps;
    };
};

export const topicPathMap: TopicPathMap<Topic> = {
    food: {
        lt: {
            title: 'Super Premium klasės maistas Jūsų augintiniams.',
            description: [
                'Oxbow maistas graužikams ir happyOne MEDITERRANEUM begrūdis maistas šunims.',
            ],
            metaTitle: 'Super Premium klasės maistas Jūsų augintiniams.',
            metaDescription:
                'Oxbow maistas graužikams ir happyOne MEDITERRANEUM begrūdis maistas šunims.',
            logos: ['oxbow', 'happyOne'],
        },
        en: {
            title: 'Super Premium Food for Your Pets.',
            description: [
                'Oxbow food for small pets and happyOne MEDITERRANEUM grain free food for dogs.',
            ],
            metaTitle: 'Super Premium Food for Your Pets.',
            metaDescription:
                'Oxbow food for small pets and happyOne MEDITERRANEUM grain free food for dogs.',
            logos: ['oxbow', 'happyOne'],
        },
    },
    hay: {
        lt: {
            title: 'Oxbow šienas graužikams.',
            description: [
                'Aukščiausios kokybės veterinarų rekomenduojamas šienas graužikams.',
                'Oxbow šienas yra auginamas tik specializuotuose Oxbow šeimos ūkiuose ir rūpestingai rūšiuojamas ir pakuojamas rankomis, todėl galite būti tikri, kad į kiekvieną pakuotę patenka tik aukščiausios kokybės ingridientai.',
            ],
            metaTitle: 'Aukščiausios kokybės Oxbow šienas graužikams',
            metaDescription:
                'Veterinarų rekomenduojamas, rūpestingai rūšiuojamas ir pakuojamas rankomis Oxbow šienas graužikams. Greitas ir patogus pristatymas paštomatu.',
            logos: ['oxbow'],
        },
        en: {
            title: 'Oxbow Hay for Small Animals.',
            description: [
                "With Oxbow western timothy, oat, botanical, alfalfa, organic, and blended hays, we have your small pet's nutritional needs covered.",
                'Oxbow works hand-in-hand with expert growers across their family of farms to ensure that all Oxbow hay has the required premium attributes that their customers expect and deserve. When hay arrives at the Oxbow farm, quality assurance process has only just begun. Hay is graded, analyzed, and altogether scrutinized before it ever enters Oxbow packaging facility.  Next, their skilled team members sort all of the highest-graded hay by hand, removing less than premium portions and putting only the best in the bag.',
            ],
            metaTitle: 'Oxbow Hay for Small Animals',
            metaDescription:
                "With Oxbow western timothy, oat, botanical, alfalfa, organic, and blended hays, we have your small pet's nutritional needs covered.",
            logos: ['oxbow'],
        },
    },
    treats: {
        lt: {
            title: 'Oxbow skanėstai graužikams.',
            description: [
                'Kiekvienas kartais nusipelnome skanėsto!',
                'Įvairių skonių Oxbow skanėstai su natūraliais vaisiais, daržovėmis ar aromatingomis žolelėmis. Jūsų augintinis tikrai neliks abejingas.',
            ],
            metaTitle: 'Aukščiausios kokybės Oxbow skanėstai graužikams',
            metaDescription:
                'Įvairių skonių Oxbow skanėstai graužikams: su natūraliais vaisiais, daržovėmis ar aromatingomis žolelėmis. Greitas ir patogus pristatymas paštomatu.',
            logos: ['oxbow'],
        },
        en: {
            title: 'Oxbow Treats for Small Animals.',
            description: [
                'Everyone deserves a treat!',
                'With our variety of Oxbow natural fruit and vegetable flavored treats, your small pets will be in heaven.',
            ],
            metaTitle: 'Oxbow Treats for Small Animals',
            metaDescription:
                'Everyone deserves a treat! With our variety of Oxbow natural fruit and vegetable flavored treats, your small pets will be in heaven.',
            logos: ['oxbow'],
        },
    },
    supplements: {
        lt: {
            title: 'Oxbow papildai graužikams.',
            description: [
                'Rūpinkitės ne tik savo, bet ir savo augintinių sveikata.',
                'Oxbow vitaminai, papildai, bei analogų neturintis atstatantis maistas Oxbow Critical Care.',
            ],
            metaTitle: 'Oxbow papildai ir vitaminai graužikams',
            metaDescription:
                'Oxbow papildai ir vitaminai graužikams, bei analogų neturintis atstatantis maistas Oxbow Critical Care. Greitas ir patogus pristatymas paštomatu.',
            logos: ['oxbow'],
        },
        en: {
            title: 'Oxbow Supplements for Small Animals.',
            description: [
                "Oxbow supplements that are packed with natural, essential vitamins to support your small pet's digestion, joints, skin, coat and urinary health.",
            ],
            metaTitle: 'Oxbow Supplements for Small Animals',
            metaDescription:
                "Oxbow supplements that are packed with natural, essential vitamins to support your small pet's digestion, joints, skin, coat and urinary health.",
            logos: ['oxbow'],
        },
    },
    accessories: {
        lt: {
            title: 'Priedai graužikams.',
            description: ['Natūralūs, graužiami žaislai graužikams bei smėlis šinšiloms.'],
            metaTitle: 'Priedai graužikams',
            metaDescription:
                'Natūralūs, graužiami žaislai graužikams, bei smėlis šinšiloms. Greitas ir patogus pristatymas paštomatu.',
            logos: ['oxbow'],
        },
        en: {
            title: 'Accessories for Small Animals.',
            description: [
                "We offer a variety of hay accessories for your small pets' habitat to make them feel right at home.",
            ],
            metaTitle: 'Accessories for Small Animals',
            metaDescription:
                "We offer a variety of hay accessories for your small pets' habitat to make them feel right at home.",
            logos: ['oxbow'],
        },
    },
    litter: {
        lt: {
            title: 'Kraikas graužikams.',
            description: ['Visiškai natūralus ir saugus kraikas graužikams.'],
            metaTitle: 'Kraikas graužikams',
            metaDescription:
                'Visiškai natūralus ir saugus kraikas graužikams. Greitas ir patogus pristatymas paštomatu.',
            logos: ['oxbow', 'freshNews'],
        },
        en: {
            title: 'Litter for Small Animals.',
            description: [
                'With a variety of bedding and litter, we have all your small pet needs to be warm and comfortable in its home.',
            ],
            metaTitle: 'Litter for Small Animals',
            metaDescription:
                'With a variety of bedding and litter, we have all your small pet needs to be warm and comfortable in its home.',
            logos: ['oxbow', 'freshNews'],
        },
    },
};
