import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';

import { Container, Spinner } from '@/ui';

import styles from './InfiniteScroll.module.scss';

type Props = {
    isLoading: boolean;
    hasNextPage: boolean;
    cursor: string;
    pageSize: number;
    callback: ({
        variables,
    }: {
        variables: { language: string; after: string; first: number };
    }) => void;
};

const InfiniteScroll: FC<Props> = ({ isLoading, hasNextPage, cursor, pageSize, callback }) => {
    const { locale } = useRouter();
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView && hasNextPage && !isLoading) {
            callback({
                variables: {
                    language: locale as Locale,
                    after: cursor,
                    first: pageSize,
                },
            });
        }
    }, [callback, locale, hasNextPage, cursor, isLoading, pageSize, inView]);

    return (
        <div ref={ref}>
            {isLoading && (
                <Container contentCenter className={styles['wrapper']}>
                    <Spinner />
                </Container>
            )}
        </div>
    );
};

export default InfiniteScroll;
