import * as Types from '../../schema';

import { gql } from '@apollo/client';
import { ProductFragmentDoc } from '../../fragments/__generated__/fragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPaginatedProductsQueryVariables = Types.Exact<{
    language: Types.Scalars['String'];
    first: Types.Scalars['Int'];
    after?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetPaginatedProductsQuery = {
    __typename?: 'Query';
    catalogue?:
        | {
              __typename?: 'Document';
              subtree: {
                  __typename?: 'SubtreeConnection';
                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; totalNodes: number };
                  edges?: Array<{
                      __typename?: 'SubtreeEdge';
                      cursor: string;
                      node:
                          | { __typename?: 'Document' }
                          | { __typename?: 'Folder' }
                          | {
                                __typename: 'Product';
                                id: string;
                                name?: string | null;
                                path?: string | null;
                                title?: {
                                    __typename?: 'Component';
                                    content?:
                                        | { __typename?: 'BooleanContent' }
                                        | { __typename?: 'ComponentChoiceContent' }
                                        | { __typename?: 'ContentChunkContent' }
                                        | { __typename?: 'DatetimeContent' }
                                        | { __typename?: 'FileContent' }
                                        | { __typename?: 'GridRelationsContent' }
                                        | { __typename?: 'ImageContent' }
                                        | { __typename?: 'ItemRelationsContent' }
                                        | { __typename?: 'LocationContent' }
                                        | { __typename?: 'NumericContent' }
                                        | { __typename?: 'ParagraphCollectionContent' }
                                        | { __typename?: 'PropertiesTableContent' }
                                        | { __typename?: 'RichTextContent' }
                                        | { __typename?: 'SelectionContent' }
                                        | { __typename: 'SingleLineContent'; text?: string | null }
                                        | { __typename?: 'VideoContent' }
                                        | null;
                                } | null;
                                topics?: Array<{
                                    __typename?: 'Topic';
                                    id: string;
                                    parentId?: string | null;
                                    name: string;
                                }> | null;
                                variants?: Array<{
                                    __typename?: 'ProductVariant';
                                    id: string;
                                    name?: string | null;
                                    sku: string;
                                    stock?: number | null;
                                    price?: number | null;
                                    priceVariants?: Array<{
                                        __typename?: 'ProductPriceVariant';
                                        identifier: string;
                                        price?: number | null;
                                    }> | null;
                                    images?: Array<{
                                        __typename?: 'Image';
                                        url: string;
                                        altText?: string | null;
                                        variants?: Array<{
                                            __typename?: 'ImageVariant';
                                            url: string;
                                            width: number;
                                            height?: number | null;
                                        }> | null;
                                    }> | null;
                                    attributes?: Array<{
                                        __typename?: 'ProductVariantAttribute';
                                        attribute: string;
                                        value?: string | null;
                                    } | null> | null;
                                }> | null;
                            };
                  }> | null;
              };
          }
        | {
              __typename?: 'Folder';
              subtree: {
                  __typename?: 'SubtreeConnection';
                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; totalNodes: number };
                  edges?: Array<{
                      __typename?: 'SubtreeEdge';
                      cursor: string;
                      node:
                          | { __typename?: 'Document' }
                          | { __typename?: 'Folder' }
                          | {
                                __typename: 'Product';
                                id: string;
                                name?: string | null;
                                path?: string | null;
                                title?: {
                                    __typename?: 'Component';
                                    content?:
                                        | { __typename?: 'BooleanContent' }
                                        | { __typename?: 'ComponentChoiceContent' }
                                        | { __typename?: 'ContentChunkContent' }
                                        | { __typename?: 'DatetimeContent' }
                                        | { __typename?: 'FileContent' }
                                        | { __typename?: 'GridRelationsContent' }
                                        | { __typename?: 'ImageContent' }
                                        | { __typename?: 'ItemRelationsContent' }
                                        | { __typename?: 'LocationContent' }
                                        | { __typename?: 'NumericContent' }
                                        | { __typename?: 'ParagraphCollectionContent' }
                                        | { __typename?: 'PropertiesTableContent' }
                                        | { __typename?: 'RichTextContent' }
                                        | { __typename?: 'SelectionContent' }
                                        | { __typename: 'SingleLineContent'; text?: string | null }
                                        | { __typename?: 'VideoContent' }
                                        | null;
                                } | null;
                                topics?: Array<{
                                    __typename?: 'Topic';
                                    id: string;
                                    parentId?: string | null;
                                    name: string;
                                }> | null;
                                variants?: Array<{
                                    __typename?: 'ProductVariant';
                                    id: string;
                                    name?: string | null;
                                    sku: string;
                                    stock?: number | null;
                                    price?: number | null;
                                    priceVariants?: Array<{
                                        __typename?: 'ProductPriceVariant';
                                        identifier: string;
                                        price?: number | null;
                                    }> | null;
                                    images?: Array<{
                                        __typename?: 'Image';
                                        url: string;
                                        altText?: string | null;
                                        variants?: Array<{
                                            __typename?: 'ImageVariant';
                                            url: string;
                                            width: number;
                                            height?: number | null;
                                        }> | null;
                                    }> | null;
                                    attributes?: Array<{
                                        __typename?: 'ProductVariantAttribute';
                                        attribute: string;
                                        value?: string | null;
                                    } | null> | null;
                                }> | null;
                            };
                  }> | null;
              };
          }
        | {
              __typename?: 'Product';
              subtree: {
                  __typename?: 'SubtreeConnection';
                  pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; totalNodes: number };
                  edges?: Array<{
                      __typename?: 'SubtreeEdge';
                      cursor: string;
                      node:
                          | { __typename?: 'Document' }
                          | { __typename?: 'Folder' }
                          | {
                                __typename: 'Product';
                                id: string;
                                name?: string | null;
                                path?: string | null;
                                title?: {
                                    __typename?: 'Component';
                                    content?:
                                        | { __typename?: 'BooleanContent' }
                                        | { __typename?: 'ComponentChoiceContent' }
                                        | { __typename?: 'ContentChunkContent' }
                                        | { __typename?: 'DatetimeContent' }
                                        | { __typename?: 'FileContent' }
                                        | { __typename?: 'GridRelationsContent' }
                                        | { __typename?: 'ImageContent' }
                                        | { __typename?: 'ItemRelationsContent' }
                                        | { __typename?: 'LocationContent' }
                                        | { __typename?: 'NumericContent' }
                                        | { __typename?: 'ParagraphCollectionContent' }
                                        | { __typename?: 'PropertiesTableContent' }
                                        | { __typename?: 'RichTextContent' }
                                        | { __typename?: 'SelectionContent' }
                                        | { __typename: 'SingleLineContent'; text?: string | null }
                                        | { __typename?: 'VideoContent' }
                                        | null;
                                } | null;
                                topics?: Array<{
                                    __typename?: 'Topic';
                                    id: string;
                                    parentId?: string | null;
                                    name: string;
                                }> | null;
                                variants?: Array<{
                                    __typename?: 'ProductVariant';
                                    id: string;
                                    name?: string | null;
                                    sku: string;
                                    stock?: number | null;
                                    price?: number | null;
                                    priceVariants?: Array<{
                                        __typename?: 'ProductPriceVariant';
                                        identifier: string;
                                        price?: number | null;
                                    }> | null;
                                    images?: Array<{
                                        __typename?: 'Image';
                                        url: string;
                                        altText?: string | null;
                                        variants?: Array<{
                                            __typename?: 'ImageVariant';
                                            url: string;
                                            width: number;
                                            height?: number | null;
                                        }> | null;
                                    }> | null;
                                    attributes?: Array<{
                                        __typename?: 'ProductVariantAttribute';
                                        attribute: string;
                                        value?: string | null;
                                    } | null> | null;
                                }> | null;
                            };
                  }> | null;
              };
          }
        | null;
};

export const GetPaginatedProductsDocument = gql`
    query GetPaginatedProducts($language: String!, $first: Int!, $after: String) {
        catalogue(language: $language, path: "/shop") {
            subtree(first: $first, after: $after)
                @connection(key: "GetPaginatedProducts", filter: ["path"]) {
                pageInfo {
                    hasNextPage
                    totalNodes
                }
                edges {
                    cursor
                    node {
                        ...product
                    }
                }
            }
        }
    }
    ${ProductFragmentDoc}
`;

/**
 * __useGetPaginatedProductsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPaginatedProductsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetPaginatedProductsQuery,
        GetPaginatedProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPaginatedProductsQuery, GetPaginatedProductsQueryVariables>(
        GetPaginatedProductsDocument,
        options
    );
}
export function useGetPaginatedProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPaginatedProductsQuery,
        GetPaginatedProductsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPaginatedProductsQuery, GetPaginatedProductsQueryVariables>(
        GetPaginatedProductsDocument,
        options
    );
}
export type GetPaginatedProductsQueryHookResult = ReturnType<typeof useGetPaginatedProductsQuery>;
export type GetPaginatedProductsLazyQueryHookResult = ReturnType<
    typeof useGetPaginatedProductsLazyQuery
>;
export type GetPaginatedProductsQueryResult = Apollo.QueryResult<
    GetPaginatedProductsQuery,
    GetPaginatedProductsQueryVariables
>;
