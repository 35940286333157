import { NextPage, GetStaticProps, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { loadIntlMessages } from '@/intl';

import { initializeApollo, addApolloState } from '@/apollo/client';
import {
    GetPaginatedProductsDocument,
    GetPaginatedProductsQuery,
    GetPaginatedProductsQueryVariables,
} from '@/catalogueQueries/GetPaginatedProductsQuery';

import { BCTitle } from '@/components/BCTitle';
import { ShopItemList } from '@/components/ShopItemList';

import { logoLinkMap } from '@/data/productTypesTopics';

import styles from '@/styles/Shop.module.scss';

export const PAGE_SIZE = 25;
const LOGO_LINKS = [logoLinkMap.oxbow, logoLinkMap.happyOne];

const messages = defineMessages({
    metaTitle: {
        id: 'pages.index.metaTitle',
        defaultMessage: 'Prekės Jūsų augintiniams | Baltic Chinchillas',
    },
    metaDescription: {
        id: 'pages.index.metaDescription',
        defaultMessage:
            'Gyvūnų prekės internetu. Geriausios kokybės prekės su greitu ir patogiu pristatymu paštomatu.',
    },
});

export const getStaticProps: GetStaticProps = async ({ locale, defaultLocale }) => {
    const apolloClient = initializeApollo();

    try {
        await apolloClient.query<GetPaginatedProductsQuery, GetPaginatedProductsQueryVariables>({
            query: GetPaginatedProductsDocument,
            variables: {
                language: locale as Locale,
                first: PAGE_SIZE,
                after: null,
            },
            fetchPolicy: 'cache-first',
        });
    } catch (e) {
        console.log(e);
    }

    return {
        ...addApolloState(apolloClient, {
            props: {
                intlMessages: await loadIntlMessages({ locale, defaultLocale }),
            },
        }),
    };
};

type Props = InferGetStaticPropsType<typeof getStaticProps>;

const Index: NextPage<Props> = () => {
    const { formatMessage } = useIntl();
    return (
        <div className={styles['wrapper']}>
            <Head>
                <title>{formatMessage(messages.metaTitle)}</title>
                <meta name="description" content={formatMessage(messages.metaDescription)} />
            </Head>
            <BCTitle />
            <div className={styles['shop']}>
                <div className={styles['header']}>
                    <div className={styles['logoWrapper']}>
                        {LOGO_LINKS.map((l, index) => (
                            <div key={index} className={styles['logo']}>
                                {l}
                            </div>
                        ))}
                    </div>
                    <div className={styles['copy']}>
                        <h1 className={styles['title']}>
                            <FormattedMessage
                                id="pages.index.h1"
                                defaultMessage="Prekės Jūsų augintiniams"
                            />
                        </h1>
                        <p className={styles['description']}>
                            <FormattedMessage
                                id="pages.index.description"
                                defaultMessage="Tik geriausios kokybės prekės Jūsų augintiniams: veterinarų rekomenduojami, amerikietiški Oxbow produktai graužikams ir begrūdis happyOne MEDITERRANEUM maistas šunims."
                            />
                        </p>
                    </div>
                </div>
                <ShopItemList />
            </div>
        </div>
    );
};

export default Index;
