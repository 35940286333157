import { FC, ReactNode } from 'react';

import styles from './BCTitle.module.scss';

type Props = {
    title?: ReactNode;
};

const BCTitle: FC<Props> = ({ title }) => (
    <div className={styles['wrapper']}>
        <span className={styles['title']}>{title || 'Baltic chinchillas'}</span>
    </div>
);

export default BCTitle;
